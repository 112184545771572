/*---- custom css ---- */
body {	
  font-family:Montserrat, "Open Sans", sans-serif;
  font-size:16px;
  color:#586372;
  font-weight:400;
}

.margin-left-0{
  margin-left: 0px !important;
}
a {	color:#178DB1 ;	}
a:hover {	color:#3fa251 }
.a-wo-href { color: #007bff!important;}

.ln-ht {
  line-height: 1.5;
}
 
h2 span, .h2 span {  border-bottom:3px ; display:inline-block; padding-bottom:8px; margin-bottom:8px; }
h3 span, .h3 span {  border-bottom:2px solid #3fa051; display:inline-block; padding-bottom:8px; margin-bottom:8px; }
.founder h4 span{  border-bottom:3px solid #3fa051; display:inline-block; padding-bottom:8px; margin-bottom:8px; }
.founder{
	text-align:center;
	margin-top: 15px;
}
.founder-quote{
  font-style: italic;
}
.founder-quote:before {
  content: '\201C';
}
.founder-quote:after {
  content: '\201D';
}
.light {
  font-weight: 500;
}
.money-raised, .h1.money-raised {
  font-size: 76px;
  font-weight: 400;
}
h1,.h1 {
	font-family: Montserrat, "Open Sans", sans-serif;
	font-size: 60px;
	font-weight: 400;
	line-height:1;
	padding: 0px;
	margin: 0px;
}
h2,.h2 {
	font-family: Montserrat, "Open Sans", sans-serif;
	font-size: 48px;
	font-weight: 400;
	line-height:1;
	padding: 0px;
	margin: 0px;
	text-transform: uppercase;
}
h2.smaller {
  font-size: 38px;
}
h3,.h3 {
	font-family: Montserrat, "Open Sans", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height:1.2;
	padding: 0px;
	margin: 0px;
}

h4,.h4 {
	font-family: Montserrat, "Open Sans", sans-serif;
	font-size: 21px;
	font-weight:700;
	padding: 0px;
	margin: 0px;
	line-height:1.2;
}

h5,.h5  {
	font-family: Lora, "Times New Roman", serif;
	font-size: 21px;
	font-weight:400;
	line-height: 1.7;
	padding: 0px;
	margin: 0px;
}


h6,.h6 {
	font-family: Lora, "Times New Roman", serif;
	font-size: 18px;
	font-weight:400;
	line-height: 1.5;
	padding: 0px;
	margin: 0px;
}

h2, .h2 .aboutus-tittle {
    text-transform: inherit;
}
.number-font-size {
  font-size: 40px;
}
.faq-text{
	padding-top: 10px;
    padding-bottom: 10px;
}
.animate:focus {
   -webkit-transition: height 50ms ease-in-out;
      -moz-transition: height 50ms ease-in-out;
        -o-transition: height 50ms ease-in-out;
           transition: height 50ms ease-in-out;
 }

#login-facebook {
  color: #3b5998;
}
#login-google {
  color: #dd4b39;
}

.txt-dark-grey, .txt-dark-grey-np { color:#2e3b4d; }
.txt-md-grey { color:#8e909c; }
.txt-light-grey { color:#eeeeee; }
.txt-dark-white{color:#fff;}
/*---- custom css ---- */

.font-lora {font-family: Lora, "Times New Roman", serif !important; font-weight:400}

.navbar {
  height: 100px;
  /* font-size: 16px; */
  transition: height 0.2s ease-in-out;
  /* transition: font-size 0.5s ease-in-out, height 0.5s ease-in-out; */
}

.navbar-brand img {	height:75px; transition:all 0.5s;	}
.navbar-right-wo-margin {margin: 25px 0 0 0;  transition:margin 0.5s; margin-right: 0 !important;}
.navbar-collapse .nav>li {	display:block; text-align:center	}
.navbar-toggle { margin-top:30px; padding-bottom:15px;   transition:margin 0.5s;	}
.navbar.small .navbar-toggle {	margin-top:15px; }
span.icon-bar { background:#00b654}
.navbar-nav .dropdown-menu li a:hover { background:rgba(0, 182, 84, 0.42); /* border-bottom: 2px solid #00a24a; */ color:#000;  }	 
.navbar-nav .dropdown-menu { -webkit-box-shadow: 0 0 4px rgba(0,0,0,.4); box-shadow: 0 0 4px rgba(0,0,0,.4);}
.hover {
  cursor: pointer;
}
.navbar-bg-dark {
  /* background-color: rgba(0,0,0,.8); */
  background-color: black;
}
.navbar-bg-gray {
  background-color: #343a40!important;
}
.navbar-shrink{
  height: 79px;
  /* font-size: 12.8px; */
}

.img-non-shrink{
  margin: 0px;
  margin-top: 0px;
  transition: transform 0.5s ease-in-out;
}
.img-shrink {
  margin: -14px;
  margin-top: -5px;
  transform: scale(0.8);
}
.shrink {
  transition: transform 0.5s;
  transform: scale(0.8);
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 98, 204, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}
.btn-default {	
  outline:0;
  border-color:#00b654;
  background-color: #fff;
  color:#acadb6;
  font-size:16px;
  text-transform:uppercase;
  padding:7px 14px;
  border-radius:40px;
}
.btn-default:hover, .btn-default:active, .btn-default:focus {
  color:#fff;
  background-image: -webkit-linear-gradient(top, #29c30d 0%, #167b04 100%);
  background-image:      -o-linear-gradient(top, #29c30d 0%, #167b04 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#29c30d), to(#167b04));
  background-image:         linear-gradient(to bottom, #29c30d 0%, #167b04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29c30d', endColorstr='#167b04', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {	
  border-color:#29c30d;
  background-image: -webkit-linear-gradient(top, #29c30d 0%, #167b04 100%);
  background-image:      -o-linear-gradient(top, #29c30d 0%, #167b04 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#29c30d), to(#167b04));
  background-image:         linear-gradient(to bottom, #29c30d 0%, #167b04 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#29c30d', endColorstr='#167b04', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
} 
.btn-primary {
  outline:0;
  border-color:#2180ff;
  background-image: -webkit-linear-gradient(top, #2180ff 0%, #064eae 100%);
  background-image:      -o-linear-gradient(top, #2180ff 0%, #064eae 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2180ff), to(#064eae));
  background-image:         linear-gradient(to bottom, #2180ff 0%, #064eae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2180ff', endColorstr='#064eae', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  background-repeat: repeat-x;
  font-size:16px;
  text-transform: uppercase;
  padding:7px 14px;
  border-radius:40px;
}

 
.btn-primary:disabled {
	color: #FFFFFF;
	background-color: #CCCCCC;
	border: 1px solid #CCCCCC;	
}
.nav > li {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
}

.shadow-btm {
  -webkit-box-shadow:0 6px 6px -6px rgba(51,51,51,.6);box-shadow: 0 6px 6px -6px rgba(51,51,51,.6);
}

footer {	background:#fff; padding:15px; font-size:16px; 	}
footer, footer a, footer a:hover { color:#2d3a4d; }
footer ul, footer li {	list-style:none; margin:0; padding:0	}
footer li {	display:inline; margin:0 5px	}
footer li i { font-size:16px !important; margin-right:4px;	} 
.footer-contactorg{
  text-decoration: none;
}
.footer-contactorg:hover{
  text-decoration : underline;
}
.prof-wrp { border-bottom:6px solid #00ccdd }
.profile-image-wrp {	float:left; margin:0 36px 50px 0;	}
.profile-image { width: 128px; height: 128px; border-radius: 50%; border: 6px solid #00ccdd; }
.prof-dates {	padding:90px 0 50px 40px; color:#00ccdd 	}
.prof-dates span {	font-size:28px; display:inline-block; padding:0 0 0 5px; 	}
.prof-dates .h4  {	color:#2e3b4d	}
.prof-dates .col-xs-6 {	padding-top:5px; padding-bottom:5px;	}
.prof-dates .col-xs-6:first-child { border-right:1px solid #a6a6a6}
.soc-icon-container i {	display:inline-block; width:35px; height:35px; text-align:center; line-height:35px; border-radius:2px; color:#fff; cursor:pointer; margin-right:10px; font-size:16px	}
.prof-mast h1 {	padding:5px 0 30px 0 ;	}
.prof-mast { padding:50px 0 5px 100px; border-right:1px solid #a6a6a6 }

.prof-timeline {	margin:30px 0 70px 0;	}
.prof-timeline.givenow-account {
    margin-left: 18px;
}

.soc-icon-container {	padding-top:15px;	}
.soc-icon-container.floated {	display:inline-block; border-left:1px solid #a6a6a6; padding:7px 0 7px 20px;  margin-left:20px; 	}
.soc-icon-container span.fa-stack {	margin:5px 0 -10px -1px; display:none;	} 
.soc-icon-container span.fa-stack:focus {	outline:0 !important	}

.circle-update { width:105px;height:105px;border-radius:50%; padding:23px 0px;text-align:center;text-transform:uppercase; position:relative;}
.white-f {color:#FFFFFF;}
.brandteal-bg {background-color:#00c1ac;}
.circle-update .h4 em {	font-style:normal; font-size:12px;text-transform:none; display:block;	}

.prof-timeline .prof-mrg img {	margin-top:15px;	}
.prof-timeline-inr h6 .prof-timeline-inr h5{ line-height:normal; padding:6px 0; }
.prof-timeline-inr h5.sub-act{ line-height:normal; padding:25px 0;}
.link-txt { font-size:12px; padding-top:6px; font-family: Lora, "Times New Roman", serif;	}

.prof-timelinebg .prof-timeline-mast:last-child .circle-update:after {	display:none	}
.activity-feed-learn-more { margin:0px 0px 88px 125px; color: #3fa251; }

.share-txt { border:1px solid rgba(0%,0%,0%,.2);  margin:16px auto 0px auto; max-width:80%; width:615px;  border-bottom:0px;}
.cont-col-wrp { padding:100px 150px 80px 150px;}
.cont-col-wrp .cont-col { float:left; width:18%; text-align:center;   }
.cont-col-wrp .spacer {	float:left; width:23%	}
.cont-col-wrp img { max-width:100%; }
.cont-col-wrp h3 { margin:15px 0 45px 0 }

.cont-padding h3, .cont-padding .h3.ngotittle {	text-transform: uppercase; }	
.newarticle.h5 { font-size: 19px; }
.home-cont2 {	background:#2d3a4d; color:#fff; margin-bottom:1px;	}

.cust-h4 {	margin-top:100px;	}

/* .home-bg {	background:url(/gr-runtime/images/home-bg2.jpg) no-repeat center top; background-size:cover; display:flex;	} */
.btn-lg  {transform: scale(1.3, 1.3);}
.tooltip > .tooltip-inner {background-color: #2180ff;}
.select.dropdown-menu {
  max-height: 20vh;
  overflow: auto;
}
/* footer {
  min-height: 5vh;
} */

/* .page-givenow {
  background:url(/gr-runtime/images/covid-19/cover/crowd.jpg)  no-repeat center top fixed;
  background-size:cover;
  min-height: 100vh;
} */
/* .page-login {
  background:url(/gr-runtime/images/covid-19/cover/crowd.jpg)  no-repeat center top fixed;
  background-size:cover;
  min-height: 100vh;
} */
.page-login .card-footer {
  background-color: #fff;
}
.cont-box-wrp {	 width:60%; margin:110px auto 15px auto; border-top:6px solid #00ccdd;  border-bottom:6px solid #00ccdd; box-shadow: 0px 0px 10px #000000; position:relative ; padding:90px 40px 50px 40px; background:rgba(255,255,255,1);	}
.cont-box-wrp .logo {
  /* height: 80px; */
  /* width: 80px; */
  /* border-radius: 52.5%; */
  /* border: 6px solid #3fa251; */
  /* box-shadow: 0px 0px 10px #000000; */
  position: absolute;
  left: 50%;
  top: 0;
  margin: -41px 0 0 -56px;
  /* background-color: #fff; */
  } 
.btn-row, .social-login {	padding:10px 0 30px 0; text-align:center	}
.btn-row i { border-radius:3px; color:#fff;   padding:15px 20px; cursor:pointer; margin:0 5px 10px 5px; }
.btn-row i span {font-family:'montserratregular', sans-serif; padding-left:15px; }
.btn-row i.fa-facebook { background:#3b5998 }
.btn-row i.fa-google-plus {background:#dd4b39}
.btn-row i.fa-twitter{background:#00aced}
.btn-row i.fa-linkedin{background:#007bb6}
.btn-row.givenowbtn { padding: 22px 0; text-align: center; }
.btn-row i.round { border-radius:35px; }

.sub-hdng {  margin-bottom:21px; margin-top:10px; }
.sub-hdng span {	display:block; text-transform:none; font-size:11px;	color:#666; font-weight:normal; min-height:26px;	} 
button.form-control {	text-align:left; line-height:3px; overflow: hidden; white-space: nowrap; -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;	}
button.form-control .caret {   float:right	}		
.error-f.help-block { color: red;}
.help-inline {display: inline-block; }
.form-field {	padding:0 0 36px 0;	}
.cont-box-wrp .help-block { margin:5px 0 -18px 13px; }
.popover .help-block { margin:-14px 0 -18px 40px; }
.cont-box-wrp .nav-tabs-custom .help-block { margin:5px 0; margin-left: 13px;  }
.cont-box-wrp .help-inline { margin:5px 0 -18px 0; }
.cont-box-wrp .nav-tabs-custom .help-inline { margin:5px 0;  }
.form-field .nav-tabs-custom i.fa { border:1px solid #797979; border-radius:50%; width:24px; height:24px; text-align:center; line-height:24px;   	}
.form-field .nav-tabs-custom  li.dollar {	padding:8px 5px 0 0;	}
.form-field .nav-tabs-custom .col-xs-6, .form-field .nav-tabs-custom .col-xs-4,
.form-field .nav-tabs-custom .col-xs-2{
	padding:0;
}
.form-control {
  display: block;
  width: 100%;
  height: 42px;
  padding: 9px 12px;
  font-family: montserratregular, "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #586372;
  background-color: #fff;
  background-image: none;
  border: 0px;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: none;
       -o-transition: none;
          transition: none;
		  /* background-color:transparent; */
}
.form-control:focus {
  border: 0px;
  border-bottom: 2px solid #00a24a;
  margin-bottom:0px;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: none;
       -o-transition: none;
          transition: none;

}
label {font-family: Lora, "Times New Roman", serif;}
.form-control::-moz-placeholder {
  color: #a7a6a6;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #a7a6a6;
}
.form-control::-webkit-input-placeholder {
  color: #a7a6a6;
}

/* show error state */
.has-error .help-block,
.has-error .help-inline,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #ed1c24;
}
.has-error .form-control {
  border-bottom: 1px solid #ed1c24;
  border-radius: 0px;
  margin-bottom: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.has-error .form-control:focus {
  border-bottom: 2px solid #ed1c24;
  border-radius: 0px;
  margin-bottom: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* dropdown styling */

.dropdown-container {
    font-family: montserratregular, "Open Sans", sans-serif;
    font-size: 18px;
    line-height: 1.42857143;
	border: 1px solid #cccccc;
    -webkit-box-shadow: 0px 0px 1px #ccc;
            box-shadow: 0px 0px 1px #ccc;
}

.dropdown-options {
	color: #000000;
	padding: 12px;
 }

.dropdown-selected {
  background-color: #fff; 
    color: #ffffff;
	padding: 12px;
}

/* toggle choices - tabs */

.nav-tabs-custom{
	background:none;
	padding:0px;
}
.nav-tabs-custom>.nav-tabs{
	padding:0;
	margin:0;
	border-bottom-color:#cccccc;
}
.nav-tabs-custom>.nav-tabs>li{
	border-top:0px solid transparent;
	color:#797979;
	font-family: montserratregular, "Open Sans", sans-serif;
	font-size: 14px;
	font-weight:400;
}
.nav-tabs-custom>.nav-tabs>li.active{
	background-color:transparent;
	color:#23384c;
	font-family: montserratregular, "Open Sans", sans-serif;
	font-size: 14px;
	font-weight:400;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus{
	background-color:transparent;
	color:#23384c;
    cursor: default;
    border: 0px solid #ddd;
    border-bottom:2px solid #00a24a;
	outline: 0px;
}
.nav-tabs-custom>.nav-tabs>li.active>a, .nav-tabs-custom>.nav-tabs>li.active:hover>a {
   background-color:transparent;
   color:#23384c;
   outline: 0px;
}
.nav-tabs>li>a {
	color: #23384c;
    line-height: 1.428571429;
    border: 0px solid transparent;
	border-radius: 0px 0px 0 0;
	padding:10px;
}
.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
	color:#23384c;
    background-color: transparent;
	outline: 0px;
}

.navbar-padding-r-l{
  padding-left: 16px;
  padding-right: 16px;
}

/* TEXT FIELD */
.text-field {
  display: block;
  width: 100%;
  height: 100px;
  font-family: montserratregular, "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: none;
       -o-transition: none;
          transition: none;
}
.text-field:focus {
  border: 2px solid #00a24a;
   -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: none;
       -o-transition: none;
          transition: none;

}
.text-field::-moz-placeholder {
  color: #a7a6a6;
  opacity: 1;
}
.text-field::-ms-input-placeholder {
  color: #a7a6a6;
}
.text-field::-webkit-input-placeholder {
  color: #a7a6a6;
}

.help-block {	font-size:10px;	margin-left:13px;}
/* .help-inline {	font-size:10px;	} */
.home-cont h2 span, .home-cont2 h2 span {	border-bottom-color:#fff  !important	}

/*FORM COPIED FROM GIVEN STYLE */
.prof-mast h2, .prof-details h2 {	font-size:32px;	}
.popover {	max-width:450px; border-radius:3px;  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.4); box-shadow: 0 0 4px rgba(0,0,0,.4);font-family:'montserratregular', sans-serif }
.popover.bottom>.arrow:after {top:3px !important}
.popover .popover-close {	margin:12px 10px 0 0; transform: scale(1, 0.8 );	}
.popover-content .form-group {	margin-bottom:0; padding:0 0 0 35px; font-size:10px; color:#999}
.popover-content .form-group  label {	min-width:70%	}
.popover-content .checkbox label {	white-space:nowrap	}
.popover-content .form-group .form-control, .popover-content .form-group .form-control:focus {padding:5px 0; font-weight:100 !important; font-size:16px; background:none !important}
.popover-content .btn-primary { margin:10px 0 10px 22px }
.popover-title { background:none; font-size:20px; font-weight:500 !important; border:0; padding:15px 0 10px 33px	}

/* .select.dropdown-menu {	width:100% !important;  -webkit-box-shadow: 0 0 4px rgba(0,0,0,.4); box-shadow: 0 0 4px rgba(0,0,0,.4); } */
.select.dropdown-menu li a:hover {  background: rgba(63, 160, 81, 0.59);  color:#000;  }	 
.modal-header {	border:0;}

button:focus, a:focus {	outline:0 !important;	}
/* .carousel-control.left { background:url(/gr-runtime/images/chevron_white_left.png) no-repeat center transparent !important; background-size:21px !important;  } */
/* .carousel-control.right { background:url(/gr-runtime/images/chevron_white_right.png) no-repeat center transparent !important; background-size:21px !important;  } */
.carousel-inner {	width: 80%; margin:0 auto	}
.carousel-inner>.item {min-height:84px}

.soc-links a {font-size:26px; display:inline-block;margin:24px 15px 0 15px; color:#586372; cursor:pointer}
.soc-links a:hover .fa-google-plus { color:#dd4b39 }
.soc-links a:hover .fa-facebook { color:#3b5998 }
.soc-links a:hover .fa-twitter { color:#00aced }
.soc-links a:hover .fa-linkedin { color:#007bb6 }

.soc-links .soc-facebook {
  color: #4267b2;
}
.soc-links .soc-twitter {
  color: #1da1f2;
}
.soc-links .soc-instagram {
  color: #c03292;
}

/* Goal */
.text-left-our-goal-para{
  text-align: left !important;
}

@media (max-width: 1599px){
.cont-col-wrp2 .cont-col { width:30.5% }
.cont-col-wrp2 .spacer { width:13%}
} 

@media (max-width: 1280px){
.cont-col-wrp { padding:100px 35px 80px 36px;}
.cont-col-wrp2 .cont-col { width:30.5% }
.cont-col-wrp2 .spacer { width:13%}
.img-shrink {
  margin: -16px;
  margin-top: -25px;
  transform: scale(0.7);
}
} 

@media (max-width: 992px){
footer li:last-child.policy{	float:none;  padding-top:8px; padding-bottom: 10px;  padding-left: 20px; 	}
.prof-mast {	padding-left:40px; border:0	}
i.fa-lg, .prof-dates, .soc-icon-container span.fa-stack { -webkit-transform: scale(0.7);  /* Saf3.1+, Chrome */
     -moz-transform: scale(0.7);  /* FF3.5+ */
      -ms-transform: scale(0.7);  /* IE9 */
       -o-transform: scale(0.7);  /* Opera 10.5+ */
          transform: scale(0.7);
	margin-right:0 }
.prof-timeline {	padding-bottom:40px;	}
.soc-icon-container span.fa-stack { margin: 3px 0 -10px -1px; }
.cont-box-wrp .col-lg-6.col-md-6  {	margin:0 0 20px 0	}
.sub-hdng {	margin-top:30px;	}
.btn-default, .btn-default:hover, .btn-default:active, .btn-default:focus,
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {	padding:6px 6px; font-size:14px;	}
}

@media (max-width: 768px){
  body {	font-size:14px;	}	

  /* .hunger-mitao {
    margin-left: 0!important;
  } */
  .money-raised, .h1.money-raised {
    font-size: 44px;
  }
  .h1, h1 {
    font-size: 24px;
    font-weight: 700;
    line-height:1.2;
  }
  .light {
    font-weight: 500;
  }
  .h2, h2, .number-font-size {
    font-size: 21px;
    font-weight:400;
  }
  h2.smaller {
    font-size: 20px;
  }
  .h3, h3 {
    font-size: 20px;
    font-weight:400;
  }

  .h4, h4 {
    font-size: 18px;
    font-weight:700;
  }
  .h5, h5{
    font-size: 18px;
    line-height: 1.5;
  }
  .h6, h6 {
    font-size: 16px;
    line-height: 1.5;
  }
  .profile-image { width: 64px; height: 64px;   border: 4px solid #00ccdd; }
  .circle-update { width:72px;height:72px;border-radius:36px;padding:13px 0px;}
  .soc-icon-container.floated {  padding:5px 0 5px 10px;  }
  .soc-icon-container i {margin-right:0}

  .btn-lg  {	transform: scale(1.1, 1.1);	}

  .cont-col-wrp .cont-col { width:90%; margin:40px 5% 0 5% }
  .cont-col-wrp .spacer { width:0%; display:none}
  .cont-col-wrp { padding:50px 10px		}

  .cust-h3 { text-align:center }

  .cont-col-wrp h3 { margin: 15px 0 20px 0; }
  .soc-links a { font-size:22px; margin:20px 10px 0 10px; }

  .activity-feed-learn-more { margin:-110px 0px 90px 100px }
  .soc-icon-container span.fa-stack {	margin-top:-1px;	}

  .popover { max-width:100% }
  .navbar-nav>li>a:hover, .navbar-nav>li>a:focus, .navbar-nav>li>a:active, .navbar-nav>li.active>a {	 border-bottom:0}
  .navbar-collapse .navbar-right {text-align:center }
  .prof-dates   { border-top:1px solid #a6a6a6 }
  .navbar-shrink{
    height: 70px;
  }
  .img-shrink {
    margin: -20px;
    margin-top: -25px;
    transform: scale(0.66);
  }
}
@media (max-width: 479px){ 
  .cont-box-wrp {	width:95%;	}
  .profile-image-wrp { margin-left:15px; margin-right:26px; }
  .prof-dates { padding:15px; }
  .prof-dates span { font-size:22px; }
  .prof-dates .h4 {	font-size:17px;	}
  .prof-mast h2, .prof-details h2 { font-size:24px; }
  .soc-icon-container.floated { margin-left:10px; }
  .soc-icon-container.floated i, .prof-mast i { margin-left:-9px }
  .popover { max-width:96% }
  .popover-content .checkbox label { white-space:normal }
  .navbar-brand img {	height:55px; margin-top:9px;}
  .btn-default, .btn-default:hover, .btn-default:active, .btn-default:focus, .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus { padding: 5px 8px; font-size: 13px; }
  .nav-tabs-custom > .nav-tabs > li { margin-left:-3px; }
  .form-field .nav-tabs-custom li.dollar { padding-right:0; }
  h3.cust-h3 {   width:80%; margin:0 auto; font-size:14px; }
  .h3.double-giving {
    font-size: 19px;
    font-weight:400;
  }
  .btn{ display: initial !important;}
  .navbar-shrink{
    height: 70px;
  }
  .img-shrink {
    margin: -2px;
    margin-top: -7px !important;
    transform: scale(0.9);
  }
}

.ta-root > .ta-scroll-window.form-control {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(163, 173, 181, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(163, 173, 181, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(163, 173, 181, 0.6);
}

@media (min-width: 769px) and (max-width: 1399px) {
  h2, .h2 { font-size:46px;}
  h2.smaller {
    font-size: 30px;
  }
  .cont-col-wrp { padding: 20px 120px 0px 120px; }
  .cont-col  {	transform: scale(.8, .8); margin-bottom:-30px;	}
  .cont-col-wrp h3 { margin:15px 0 15px 0 }
}

@media (min-width: 320px) and (max-width: 549px) {
	.btn{ display: initial !important;}
}
.clear-both:nth-of-type(3n + 1) {
  clear: both;
}
@keyframes fadeInDown {
  0% {
      opacity: 0;
      /* transform: translateY(-20px); */
  }
  40%{
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100% {
      opacity: 0;
      /* transform: translateY(0); */
  }
}
