
span.sub-contentpay {
    font-size: 10px!important;
    color: #737373 !important;
}

.edit-button{
	margin-top:10px;
}
  
.pri_content h5 {
    font-weight: bold;
    line-height: 65px;
    font-size: 18px;
}
.pri_date {
    line-height: 70px;
}
.pri_content {
    font-size: 14px;
    color: #7D7D7D;
}
.cc-icon {
    float: right;
    margin-top: -38px;
    color: #00B654;
    margin-right: 10px;
    }
.ex-card{margin-top: 20px;}

.teams-member img{
	width: 200px;
    border-radius: 50%;
    height: 200px;
}

.founder span {
    border-bottom: 3px solid #3fa051;
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 8px;
}
.founder p {
    font-size: 15px;
}
.founder span small{
	color: #9daab3;
	font-size: 75%;
}

.amount {
    color: #00ccdd;
}

.progress{
	 height: 9px!important;
}
.camp-givenow{
    margin-top: 10px;
    margin-bottom: 47px;
}
.youtubengo-main{
    width: calc(30vw);
    height: calc(16vw);
    padding-left: 10px;
    padding-right: 10px;
}

.img-logo { height: 80px; }
.hunger-mitao {
    margin-left: -20px;
}
@media (min-width: 0px) and (max-width: 767px) {
    .youtubengo { width: 100%; height: 300px; padding-bottom:10px; padding-left: 0px; }
    .about-img { width: 100%; height:auto!important; padding-bottom:10px; padding-left: 0px; }
    .navbar-right { margin-top: 29px; transition:margin 0.5s; margin-right:20px !important;}
    .navbar.small .navbar-right  {	margin:14px 20px 0 0;	} 
    img.logo { left: 57% !important; }
    .select.dropdown-menu {min-width:50px !important; }
    /* .cont-padding {	padding:50px 0 55px 0;	} */
    .navbar.small .navbar-brand img { height: 50px;  margin-top: -6px; }
    .footer-brand img { height: 50px;  margin-top: -6px; }
    .prof-timeline .prof-mrg { float:left; margin:0 4px 50px 0;  }
    .circle-update .h4 {padding: 10px 12px;	display: block;	line-height: normal; font-size: 11px;}
    .prof-timelinebg .circle-update:after {content: "";position: absolute; left: 50%; height: 159px; width: 1px; background: #00c1ac;}
    .copy-right{ float:none;     text-align: center;}
    footer { font-size:12px !important; }
}

/*SMALL DEVICES IPAD*/
@media (min-width: 768px) and (max-width: 991px) {
    /*YOUR GIVING WILL FUND*/
    .youtubengo { float: right; width: 50%; height: 300px; padding-left: 20px; }
    /*NAVBAR*/
    .navbar-right {margin-top: 29px;  transition:margin 0.5s; margin-right:20px !important;} 
    .navbar.small .navbar-right  {	margin:9px 20px 0 0;	} 
    .select.dropdown-menu {min-width:50px !important;  max-width:95%; }
    img.logo { left: 52% !important; }
    .cont-padding {	padding:80px 0 125px 0;	}
    .navbar-nav>li { padding: 9px 5px; }
    .navbar.small .navbar-brand img { height: 50px;  margin-top: -14px; }
    .footer-brand img { height: 50px;  margin-top: -2px; }
    /*account page -activity feed*/
    .prof-timeline .prof-mrg { float:left; margin:0 28px 50px 0;  }
    .circle-update .h4 {padding: 10px 12px;	display: block;	line-height: normal; font-size: 11px;}
    .prof-timelinebg .circle-update:after {	content:""; position:absolute; left:50%; bottom:-100px; height:100px; width:1px; background:#00c1ac;	}
    .copy-right{float:none; text-align: center;}
    footer { font-size:12px !important; }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .youtubengo { float: right; width: 50%; height: 300px; padding-left: 20px; }
    .navbar-right {margin-top: 29px;  transition:margin 0.5s; margin-right:20px !important;} 
    .navbar.small .navbar-right  {	margin:6px 20px 0 0;	} 
    .select.dropdown-menu {min-width:50px !important; height:140px; max-width:95%;  overflow:auto; }
    img.logo { left: 52% !important; }
    .cont-padding {	padding:60px 0 75px 0;	}
    .navbar-nav>li { padding: 14px 5px; }
    .navbar.small .navbar-brand img { height: 50px;  margin-top: -14px; }
    .footer-brand img { height: 50px;  margin-top: -2px; }
    /* footer { font-size:16px; } */
    /*account page -activity feed*/
    .prof-timeline .prof-mrg { float:left; margin:0 28px 50px 0;  }
    .circle-update .h4 {padding: 10px 18px;	display: block;	line-height: normal; font-size: 17px;}
    .prof-timelinebg .circle-update:after {	content:""; position:absolute; left:50%; bottom:-100px; height:100px; width:1px; background:#00c1ac;}
    .copy-right {float: left;}
    .footer-contact{
        float: right;
      }
}

@media (min-width: 1200px) {
    .youtubengo { float: right; width: 50%; height: 300px; padding-left: 20px; }
    .navbar-right {margin-top: 23px;  transition:margin 0.5s; margin-right:20px !important;} 
    .navbar.small .navbar-right  {	margin:6px 20px 0 0;	} 
    .select.dropdown-menu {min-width:50px !important; height:140px; max-width:95%; overflow:auto;}
    img.logo { left: 52% !important; }
    .cont-padding {	padding:80px 0 125px 0;	}
    .navbar-nav>li { padding: 14px 5px; }
    .navbar.small .navbar-brand img { height: 60px; }
    .footer-brand img { height: 60px; }
    /* footer { font-size:16px; } */
    .teams-member img{
        width: 200px;
        border-radius: 50%;
        height: 200px;
    }
    /*account page -activity feed*/
    .prof-timeline .prof-mrg { float:left; margin:0 28px 50px 0;  }
    .circle-update .h4 {padding: 10px 18px;	display: block;	line-height: normal; font-size: 17px;}
    .prof-timelinebg .circle-update:after {	content:""; position:absolute; left:50%; bottom:-100px; height:100px; width:1px; background:#00c1ac;	}
    .copy-right {float: left;}
    .footer-contact{
        float: right;
      }
}

@media (min-width: 1920px) {
    /*account page -activity feed*/
    .prof-timeline .prof-mrg { float:left; margin:0 28px 50px 0;  }
    .circle-update .h4 {padding: 10px 18px;	display: block;	line-height: normal; font-size: 17px;}
    .prof-timelinebg .circle-update:after {	content:""; position:absolute; left:50%; bottom:-100px; height:100px; width:1px; background:#00c1ac;	}
    .copy-right {float: left;}
}

@media (min-width: 600px) and (max-width: 960px){
    .navbar-right {margin-top: 25px;  transition:margin 0.5s; margin-right:20px !important;} 
    .navbar.small .navbar-right  { margin:9px 20px 0 0; }
    img.logo { left: 52% !important; }
    .cont-padding {	padding:100px 0 125px 0;}
    .navbar-nav>li { padding: 9px 5px; }
    .copy-right {float: none; text-align: center;}
}

@media (min-width: 320px) and (max-width: 568px) and (orientation : landscape){
    .navbar-right { margin-top: 18px; transition:margin 0.5s; margin-right:20px !important;} 
    .navbar.small .navbar-right { margin: 0px 20px 0 0; }
    .copy-right{float:none; text-align: center;}
}

@media (min-width: 320px) and (max-width: 568px) and (orientation : landscape){
    .navbar-right { margin-top: 18px; transition:margin 0.5s; margin-right:20px !important;} 
    .navbar.small .navbar-right { margin: 0px 20px 0 0; }
    .copy-right{float:none; text-align: center;}
}

@media (max-width: 260px) {
    .teams-member img{
        width: 125px;
        border-radius: 50%;
        height: 125px;
    }
}