@CHARSET "ISO-8859-1";

@media (min-width: 768px) {
	.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
	    margin-left: 2px;
	    margin-top: 8px;
	}
}
.button-lower-case, .button-lower-case:hover, .button-lower-case:active, .button-lower-case:focus {
	text-transform: none;
}
.policy {
	text-align: center;
}

.nav li i {
	margin-top: -3px;
}

.cont-vert-grey-bg {
	background-color: #EEEEEE;
}
.cause-title-name {
	background-color: rgba(0,0,0,0.3);
}
.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 135px;
  margin-top: 10px;
  margin-bottom: -10px;
  text-align: center;
}
.number1.chart {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 135px;
  top: 40px;
  /* margin-top: 28px; */
  /* margin-bottom: 28px;*/
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
  /* font-size: 34px; */
  font-size: 28px;
}
.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}

.social-popover-inline a {
    font-size: 26px;
    display: inline-block;
    margin: 0 15px 0 0;
    color: #586372;
    cursor: pointer;
}

.social-popover-inline a:last-child {
  margin-right: 0;
}

.follow-us-popover-container .popover{
	border-color: #fff;
} 
.follow-us-popover-container .popover .arrow {
	border-bottom-color: #fff;
}
body .popover{
	border-color: #fff;
} 
body .popover .arrow {
	border-bottom-color: #fff;
}

.follow-us-secondary-text {
	margin-top: 30px;
}

.angular {
  margin-top: 100px;
}
/*
.angular .chart {
  margin-top: 10px;
}*/
.span6 {
  float: left;
  width: 50%;
}

.counter{
	font-size:30px;
	text-align:center;
}

.content {
    border-radius: 4px;
    padding-top: 44px;
    /* border-top: none; */
    background-color: #2E3A4D;
    opacity: 0.8;
   border-radius: 90px;
}

.head {
    /* color:#fff; */
    font-size: 13px;
}

.li-number p.font-lora {
    font-size: 16px;
}
.li-number {
	font-size: 20px;
	font-weight: bold;
}
.section-heading {
	/* padding-top: 30px; */
}
.panel-primary > .panel-heading{
	background-color:#fff;
	border-color: white;
	border-radius: 0;
	color:#586372
}
.panel-primary > .panel-heading-grey{
	background-color:#EBEFF1!important;
	border-color:#EBEFF1!important;
	border-radius: 20px;
	color:#586372;
	padding: 15px 15px!important;
}
.panel-primary > .panel-heading-green{
	background-color:#00c0ab!important;
	border-color:#00c0ab!important;	
	border-radius: 20px;
	color:#fff;
	padding: 15px 15px!important;
}
/* .panel-primary {
    border-color: #00c0ab !important;
} */

.panel{
    padding: 10px!important;
    border:none!important; 
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.panel-heading{
	padding:15px 15px!important;
}

.cause-dashboard-panel-container {
	padding-right: 0;
}

@media (max-width: 500px) {
	.cause-dashboard-panel-container {
	    padding-right: 15px;
	}
}

.cause-dashboard-panel {
	/* margin-bottom: 15px; */
	border: 2px solid transparent;
}
.cause-dashboard-panel2 {
	margin-bottom: 15px;
	border: 2px solid transparent;
	/* background-color: grey; */
	border-color: #00c0ab !important;
}
.fa-stack-1x {
    color:white;
}
.social-popover-inline {
	margin-bottom: 0px;
	color: #586372;
}
span.number {
    font-size: 40px;
    text-align: center;
    display: inline-table;
    /* vertical-align: middle; */
   	top: 20px;
    /* color: #fff; */
   /* opacity: 0.6;*/
}

.heading {
    background: #00C0AB;
    padding: 8px;
    color:#fff;
}

span.number1 {
    font-size: 35px;
    text-align: center;
    display: inline-table;
    /* vertical-align: middle; */
    /* color: #fff; */
    /* opacity: 0.6;*/
}

@media (max-width: 500px) {
	span.number1 {
	    font-size: 30px;
	}
}
article p {
	    margin-top: 10px;
}
article h4 {
	    margin-top: 5px;
}

ol.what-needs-tbd {
	background-color: #EEEEEE;
	margin-bottom: 0px !important
}
ol.what-needs-tbd {
    counter-reset:li;/* Initiate a counter */
   /*  margin-left:0; *//* Remove the default left margin */
    /* padding-left:0; *//* Remove the default left padding */
   /*  padding:0; */
}
ol.what-needs-tbd > li {
	position:relative; /* Create a positioning context */
 /*    margin:0 0 6px 2em; */ /* Give each list item a left margin to make room for the numbers */
   /*  padding:4px 8px; */ /* Add some spacing around the content */
    list-style:none; /* Disable the normal item numbering */
    /* border-top:2px solid #666;
    background:#f6f6f6; */
}
ol.what-needs-tbd > li:before {
    content:counter(li);/* Use the counter as content */
    counter-increment:li;/* Increment the counter by 1 *//* Position and style the number */
    position:absolute;
    top:-2px;
    left:-2em;
    border-radius: 50px;
    color: #3fa051;
    font-size: 24px;
    border: 3px solid #3fa051;
    display: inline;
    text-align: center;
    padding: 11px 15px;
    font-weight: 500;
    /* margin: 200px 0px 20px -70px; */
    width:2em;
    /* Some space between the number and the content in browsers that support
       generated content but not positioning it (Camino 2 is one example) */
    margin-right:8px;
    padding:4px;
}


input.customvalue.form-control.ng-pristine.ng-untouched.ng-invalid.ng-invalid-required.ng-invalid-cc-number.ng-invalid-cc-number-type.ng-not-modified {
    font-size: 16px;
    position: absolute;
    width: 100px;
}
/* li ol,
li ul {margin-top:6px;}
ol ol li:last-child {margin-bottom:0;} */

@media only screen and (max-width: 600px) {
  /* li {
    font-size: 10px;
  } */
}
@media only screen and (max-width: 430px) {
  .span6 {
    float: none;
    width: 100%;
  }
  body {
    padding-top: 0;
  }
  .let-us-help h2 {
  	text-align: center;
  }
}

.col-xs-cause-dbd-content {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-cause-dbd-content {
  float: left;
}
.col-xs-cause-dbd-content {
	width: 84%;
}

.col-xs-cause-dbd-gutter {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-cause-dbd-gutter {
  float: left;
}
.col-xs-cause-dbd-gutter {
	width: 8%;
}

.text-area {
    width: 100%;
}

.customvalue {
	font-size: 14px;
	height: 41px;
}

.customunderline{
border-bottom: 2px solid #00a24a;
 
}

