.about-why img{
  max-height: 400px;
  height: auto;
  width: 100%;
}
.indiaspora-initiative {
  color: #acadb6;
}
.border-bottom {
  border-bottom: 3px solid #2180ff!important;
}
.give-now-amount-margin{
  margin-bottom:36px;
}
.give-now-amoun-border{
  border-bottom: 1px solid #ccc;
}

.minor-underline {
  border-bottom: 2px solid #3fa051;
}
.anchor-underline {
  text-decoration: underline;
}
  /* cover section and causes */
.Hero {
  position: relative;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 768px;
}
.cause-Hero{
  position: relative;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
.position-absolute {
  position: absolute;
}
.zIndex-6 {
  z-index: 6;
}
.zIndex-1 {
  z-index: 1;
}
.zIndex-7 {
  z-index: 7;
}
.zIndex-8 {
  z-index: 8;
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.bottom-0 {
  bottom: 0;
}
.marginHorizontal-auto {
  margin-left: auto;
  margin-right: auto;
}
.backgroundColor-grayMedium {
  background-color: #888888;
}
.HomepagePhotoSlider-image-about{
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60% 5%;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
}
.HomepagePhotoSlider-image-home{
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 60% 5%;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  animation: sliderAnimation ease 5s infinite;
}

@keyframes sliderAnimation {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: .9;
  }
}

.centered-cause-image{
  background-position: 50%;
}
.cause-image{
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 100% 5%; */
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* opacity: 0.0; */
  z-index: 0;
}
.Hero-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.55);
}
.fontSize-24 {
  font-size: 24px;
}
.fontWeight-semibold {
  font-weight: 400;
}
.position-relative {
  position: relative;
}
.lineHeight-1d5 {
  line-height: 1.5;
}
.hero-down-arrow {
  position: absolute;
  bottom: 0;
  z-index: 10;
  color: #939d9d;
}
.dark-link {
  color: #c0cb19;
  text-decoration: underline;
}
.text-success{
  color: #28a745!important;
}
@media only screen and (min-width: 1200px) {
  .padding-lr-xlg {
    padding-left: 15rem!important;
    padding-right: 15rem!important;
  }
}
@media only screen and (min-width: 767px) {
  .Hero {
    height: 768px
  }
  .indiaspora-initiative {
    font-size: 14px;
  }
  .cause-Hero {
    height: 418px
  }
  .fontSize-48{
    font-size: 48px;
  }
  .fontSize-40{
    font-size: 40px;
  }
  .fontSize-36{
    font-size: 36px;
  }
  .lineHeight-1d25-m {
    line-height: 1.25;
  }
}
  /* Mobile Css */
@media only screen and (max-width: 479px) {
  .Hero {
    height: 100vh;
  }
  .indiaspora-initiative {
    position: absolute;
    bottom: -2px;
    left: 15px;
    font-size: 12px;
  }
  .paddingTop-100-m{
    padding-top: 2.5rem!important;
  }
  .paddingTop-64-m{
    padding-top: 1.5rem!important;
  }
  .paddingBottom-64-m{
    padding-bottom: 1.5rem!important;
  }
  .paddingBottom-100-m{
    padding-bottom: 1.5rem!important;
  }
  .sneha .insights .p-4,.sef .insights .p-4,
  .wishfoundation .insights .p-4,.edf .insights .p-4,
  .fea .insights .p-4,.pluse .insights .p-4,
  .magicbus .insights .p-4,
  .arogya .insights .p-4,.malaria .insights .p-4,
  .ekalvidyalaya .insights .p-4,
  .shanti-bhavan .insights .p-4,.OGQ .insights .p-4,.H2HFoundation .insights .p-4,
  .theNudge .insights .p-4,.american-India-Foundation-Edu .insights .p-4,
  .american-India-Foundation-Health .insights .p-4,.pratham .insights .p-4,
  .satyarthi .insights .p-4,.akanksha .insights .p-4,
  .bhoomika .insights .p-4,.american-India-Foundation-poverty .insights .p-4,
  .ffe .insights .p-4,
  .sewa-international .insights .p-4,


  .sneha .about .p-4,.sef .about .p-4,
  .wishfoundation .about .p-4,.edf .about .p-4,
  .fea .about .p-4,.pluse .about .p-4,
  .magicbus .about .p-4,
  .arogya .about .p-4,.malaria .about .p-4,.ekalvidyalaya .about .p-4,
  .shanti-bhavan .about .p-4,.OGQ .about .p-4,.H2HFoundation .about .p-4,
  .theNudge .about .p-4,.american-India-Foundation-Edu .about .p-4,
  .american-India-Foundation-Health .about .p-4,.pratham .about .p-4,
  .satyarthi .about .p-4,.akanksha .about .p-4,
  .bhoomika .about .p-4,.american-India-Foundation-poverty .about .p-4,
  .ffe .about .p-4,
  .sewa-international .about .p-4,


  .sneha .what-needs-to-be-done .p-4,.sef .what-needs-to-be-done .p-4,
  .wishfoundation .what-needs-to-be-done .p-4,.edf .what-needs-to-be-done .p-4,
  .fea .what-needs-to-be-done .p-4,.pluse .what-needs-to-be-done .p-4,
  .magicbus .what-needs-to-be-done .p-4,
  .arogya .what-needs-to-be-done .p-4,
  .malaria .what-needs-to-be-done .p-4,.ekalvidyalaya .what-needs-to-be-done .p-4,
  .shanti-bhavan .what-needs-to-be-done .p-4,.OGQ .what-needs-to-be-done .p-4,.H2HFoundation .what-needs-to-be-done .p-4,
  .theNudge .what-needs-to-be-done .p-4
  ,.american-India-Foundation-Edu .what-needs-to-be-done .p-4,
  .american-India-Foundation-Health .what-needs-to-be-done .p-4,.pratham .what-needs-to-be-done .p-4
  .satyarthi .what-needs-to-be-done .p-4,.akanksha .what-needs-to-be-done .p-4,
  .bhoomika .what-needs-to-be-done .p-4,.american-India-Foundation-poverty .what-needs-to-be-done .p-4
  .ffe .what-needs-to-be-done .p-4,
  .sewa-international .what-needs-to-be-done .p-4
  {
    padding: 0!important;
  }
  .arogya-cover-img,.magicbus-cover-img{
    background-position: 70% 7%;
  }
  .comming-soon-time h2{
    font-size: 18px;
    font-weight: 700;
  }
  .comming-soon-time h4{
    font-size: 14px;
    font-weight: 500;
  }
  .cont-box-wrp {	 
    margin:129px auto 15px auto!important; 
    padding:72px 40px 50px 40px!important;
  }
  .cont-padding{
    padding: 121px 0 125px 0;
  }
  .logo-250{
    max-width: 250px;
  }
}

.about-img {
  width: 100%;
  height:200px;
  padding-bottom:10px;
  padding-left: 0px;
}
img.img-circle {
    border-radius: 50%;
    width: 40px;
    height: auto;
    border: 3px;
    border-color: lightcyan;
    border-style: solid;
}
.currency{
  border: 1px solid #797979;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.paddingTop-100-m{
  padding-top: 100px;
}
.paddingBottom-100-m{
  padding-bottom: 100px;
}
.paddingBottom-64-m {
  padding-bottom: 64px;
}
.youtubengo {
  float: right;
  width: 50%;
  height: 300px;
  padding-left: 20px;
}
#more {
  display: none;
}
.navbar-nav  a {
  color:#acadb6;
} 
.customClassForDropDown{
  height: 300px;
  overflow-y: auto;
}
.customClassForCauseDropDown{
  height: 120px;
  overflow-y: auto;
}

.spread-the-word {
	background: rgba(255,255,255,0.7);
}

@media (min-width: 550) {
  .popover {
    max-width: 450px;
    width: 450px;
  }
}

@media (min-width: 320px) and (max-width: 549px) {
  .popover {
    max-width: 310px;
    width: 310px;
  }
}
.spread-the-word {
	background: rgba(255,255,255,0.7);
}
.profile-picture {
  height: 100px;
  display: block;
}

.small-descriptions, .moment-suffix {
	font-family: "Open Sans", sans-serif;
	font-size: 14px;
	font-weight:400;
	display: block;
}
.small-descriptions-mobile {
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	font-weight:400;	
}

.has-error .help-block {
	padding: 20px 0;
}
.error-f {
	color: #ed1c24;
}
/* show error state */
.has-error .form-field {
  border-bottom: 1px solid #ed1c24;
  border-radius: 0px;
  margin-bottom: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.has-error .form-field:focus {
  border-bottom: 2px solid #ed1c24;
  border-radius: 0px;
  margin-bottom: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* Landing Page chagnes */

html { 
    /* background: url('/gr-runtime/images/covid-19/cover/crowd.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; */
    background-color: #888888;
}
 
  